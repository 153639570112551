<template>
  <div class="mobilePage">
    <!-- <div class="header">
      <div class="logo"></div>
      <div class="contactOfficial" @click="toTg"></div>
    </div> -->
    <div class="content">
      <div class="contactBox">
        <div
          class="telegram contactItem"
          @click="toPage('https://t.me/liangnianbanlaosiji')"
        ></div>
        <div
          class="potato contactItem"
          @click="toPage('https://dlptm.org/lnbsq')"
        ></div>
        <div
          class="business contactItem"
          @click="toPage('https://t.me/ShangWuhXiao2Ge')"
        ></div>
      </div>
      <div class="title"></div>
      <div
        class="downloadBtn"
        @click="getApkInfo(os.isPhone ? 'ios' : 'android')"
      ></div>
      <div class="main">
        <van-tabs
          v-model="activeIndex"
          animated
          swipeable
          :scrollspy="false"
          background="rgba(0,0,0,0)"
        >
          <van-tab title="国产" key="0">
            <div class="listBox">
              <div
                class="bg1"
                v-if="os.isPhone"
                @click="getApkInfo('ios')"
              ></div>
              <div class="bg1" v-else @click="getApkInfo('android')"></div>
            </div>
          </van-tab>
          <van-tab title="动漫" key="1">
            <div class="listBox">
              <div
                class="bg2"
                v-if="os.isPhone"
                @click="getApkInfo('ios')"
              ></div>
              <div class="bg2" v-else @click="getApkInfo('android')"></div>
            </div>
          </van-tab>
          <van-tab title="黑料" key="2">
            <div class="listBox">
              <div
                class="bg3"
                v-if="os.isPhone"
                @click="getApkInfo('ios')"
              ></div>
              <div class="bg3" v-else @click="getApkInfo('android')"></div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="bottomBox" @click="getApkInfo(os.isPhone ? 'ios' : 'android')">
      <!-- <div
        class="downloadBtn"
        @click="getApkInfo('ios')"
        v-if="os.isPhone"
      ></div> -->
      <!-- <div class="downloadBtn" v-else @click="getApkInfo('android')"></div> -->
    </div>
    <!-- 安卓提示框 -->
    <van-overlay :show="androidPromptShow" z-index="999" class="androidOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="confirmBtnBox" @click="androidPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <!-- 苹果提示框 -->
    <van-overlay :show="iosPromptShow" z-index="999" class="iosOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="appName">两年半</div>
          <div class="desc">全免费 更丰富 更高清</div>
          <div class="confirmBtnBox" @click="iosPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <van-popup v-model="showTip" class="downTip">
      <div class="downTipTitle">温馨提示</div>
      <div class="downTipContent">
        请使用Safari浏览器进行下载
      </div>
      <div class="url">{{ currUrl }}</div>
      <div class="downTipConfirm" @click.stop="doCopy">复制链接</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ["os"],
  data() {
    return {
      androidPromptShow: false,
      iosPromptShow: false,
      showTip: false,
      currUrl: window.location.href,
      activeIndex: 0,
      tabs: [
        {
          label: "国产",
          value: 0,
        },
        {
          label: "动漫",
          value: 1,
        },
        {
          label: "黑料",
          value: 2,
        },
      ],
    };
  },
  mounted() {
    let ua = this.os.userAgent.toLowerCase();
    let isSafari =
      ua.indexOf("safari") !== -1 &&
      ua.indexOf("browser") === -1 &&
      ua.indexOf("android") === -1;
    if (!isSafari && this.os.isPhone) {
      this.showTip = true;
    }
    const referrer = sessionStorage.getItem("referrer");
    const dc = sessionStorage.getItem("dc");
    if (referrer && dc) {
      this.currUrl = referrer + "user.html?dc=" + dc;
    } else {
      if (referrer) {
        this.currUrl = referrer + "user.html";
      } else {
        this.currUrl = window.location.href;
      }
    }
  },
  methods: {
    // 剪贴板
    doCopy() {
      this.$copyText(this.currUrl).then(
        () => {
          console.log("复制成功");
          this.showTip = false;
        },
        () => {
          console.log("复制失败");
        }
      );
    },
    async showInstallTutorial(type) {
      if (type == "ios") {
        this.iosPromptShow = true;
      } else {
        this.androidPromptShow = true;
      }
    },
    async getApkInfo(type) {
      await this.showInstallTutorial(type);
      setTimeout(() => {
        this.$emit("getApkInfo", type);
      }, 500);
    },
    toPage(path) {
      window.open(path);
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
.mobilePage {
  overflow-y: auto;
  height: 100vh;
  width: 100%;
  .content {
    width: 100%;
    background: url("./../../../assets/images/mobile1/bg.webp") no-repeat;
    background-size: 100% 812px;
    background-color: white;
    padding: 342px 0 88px;
    box-sizing: border-box;
    position: relative;
    .contactBox {
      width: 55px;
      height: 210px;
      border-radius: 28px;
      border: 1px solid #fff;
      background: rgba(17, 17, 17, 0.27);
      padding: 30px 6px;
      box-sizing: border-box;
      position: fixed;
      top: 50px;
      right: 2px;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .contactItem {
        width: 43px;
        height: 42px;
      }
      .telegram {
        background: url("./../../../assets/images/mobile1/telegramBox.png")
          no-repeat;
        background-size: 100% 100%;
      }
      .potato {
        background: url("./../../../assets/images/mobile1/potatoBox.png")
          no-repeat;
        background-size: 100% 100%;
      }
      .business {
        background: url("./../../../assets/images/mobile1/businessBox.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .title {
    width: 328px;
    height: 104px;
    background: url("./../../../assets/images/mobile1/title.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
  }
  .downloadBtn {
    width: 144px;
    height: 28px;
    margin: 0 auto 20px;
    background: url("./../../../assets/images/mobile1/downloadBtn.png")
      no-repeat;
    background-size: 100% 100%;
  }
  .main {
    padding: 10px 10px 0;
    height: 100%;
    ::v-deep .van-tabs {
      height: 100%;
      .van-tabs__wrap {
        height: 35px;
        border-radius: 4px;
        border: 1px solid #e0e0e0;
        .van-tabs__nav {
          display: flex;
          align-items: center;
          padding: 0 4px;
          .van-tab {
            width: 112px;
            height: 29px;
            border-radius: 4px;
            color: #fff;
            font-size: 12px;
            font-weight: 600;
          }
          .van-tab--active {
            color: white;
            background: linear-gradient(to right, #9cccfa, #c5e2fc);
          }
          .van-tabs__line {
            display: none;
          }
        }
      }
      .van-tabs__content {
        height: 100%;
        .van-tabs__track {
          height: 100%;
          .van-tab__pane-wrapper {
            height: 100%;
            .van-tab__pane {
              height: 100%;
            }
          }
        }
      }
    }
    .listBox {
      height: calc(100% - 181px);
      // overflow-y: auto;
      padding-top: 10px;
      .bg1 {
        height: 620px;
        background: url("./../../../assets/images/mobile1/bg1.png") no-repeat;
        background-size: 100% 100%;
      }
      .bg2 {
        height: 620px;
        background: url("./../../../assets/images/mobile1/bg2.png") no-repeat;
        background-size: 100% 100%;
      }
      .bg3 {
        height: 620px;
        background: url("./../../../assets/images/mobile1/bg3.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .bottomBox {
    height: 68px;
    width: 375px;
    position: fixed;
    left: 0;
    bottom: 0;
    background: url("./../../../assets/images/mobile1/bottomBox.png") no-repeat;
    background-size: 100% 100%;
    .downloadBtn {
      height: 32px;
      width: 96px;
      border-radius: 5px;
      position: absolute;
      right: 16px;
      top: 20px;
    }
  }
  .androidOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/android_tip.png")
        no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }
  .iosOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/iosTip.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      position: relative;
      .appName {
        font-size: 10px;
        font-weight: 500;
        color: #242423;
        position: absolute;
        left: 79px;
        bottom: 102px;
        z-index: 1;
        background-color: #ffffff;
        width: 100px;
      }
      .desc {
        font-size: 7px;
        font-weight: 500;
        color: #8f908f;
        position: absolute;
        left: 79px;
        bottom: 92px;
        z-index: 1;
        background-color: #ffffff;
        width: 100px;
      }
      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }
}
.downTip {
  border-radius: 10px;
  padding: 18px;
  box-sizing: border-box;
  text-align: center;
  .downTipTitle {
    font-size: 18px;
    margin-bottom: 34px;
  }
  .downTipContent {
    white-space: nowrap;
    font-size: 15px;
  }
  .url {
    font-size: 15px;
    margin-top: 16px;
    text-align: left;
  }
  .downTipConfirm {
    // margin-top: 12px;
    font-size: 15px;
    width: 160px;
    height: 30px;
    border-radius: 40px;
    margin: 32px auto 0;
    color: #fff;
    background: #5b92ee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
