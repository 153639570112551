<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox">
      <div class="telegramLogo" @click="toTg"></div>
      <div class="potatoLogo" @click="toPotato"></div>
    </div>
    <div class="titleBox"></div>
    <div class="btnBox">
      <div class="iosBox">
        <div class="iosBtn"></div>
        <div class="qrBox">
          <img class="qrcodeBox" :src="qrImg" alt="" />
        </div>
      </div>
      <div class="androidBox">
        <div class="androidBtn"></div>
        <div class="qrBox">
          <img class="qrcodeBox" :src="qrImg" alt="" />
        </div>
      </div>
    </div>
    <div class="tip"></div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    toTg() {
      window.open("https://t.me/liangnianbanlaosiji");
    },
    toPotato() {
      window.open("https://dlptm.org/lnbsq");
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.webp") no-repeat;
  background-size: 100% 100%;
  .logo {
    position: fixed;
    top: 66px;
    left: 228px;
    width: 272px;
    height: 92px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
  }
  .contactBox {
    position: fixed;
    top: 75px;
    right: 216px;
    display: flex;
    align-items: center;
    .telegramLogo {
      width: 68px;
      height: 68px;
      margin-right: 50px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
    }
    .potatoLogo {
      width: 68px;
      height: 68px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .titleBox {
    width: 1031px;
    height: 328px;
    position: fixed;
    right: 75px;
    bottom: 425px;
    background: url("./../../../assets/images/pc/title.png") no-repeat;
    background-size: 100% 100%;
  }
  .btnBox {
    position: fixed;
    bottom: 273px;
    right: 425px;
    display: flex;
    align-items: flex-end;
    .iosBox {
      margin-right: 22px;
      .iosBtn {
        width: 163px;
        height: 57px;
        background: url("./../../../assets/images/pc/iosBtn.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .androidBox {
      margin-right: 22px;
      .androidBtn {
        width: 163px;
        height: 57px;
        background: url("./../../../assets/images/pc/androidBtn.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .qrBox {
      width: 163px;
      height: 163px;
      border-radius: 11px;
      border: 1px solid #fff;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;
      .qrcodeBox {
        width: 143px;
        height: 143px;
      }
    }
    .iosBox:hover {
      .iosBtn {
        display: none;
      }
      .qrBox {
        display: flex;
      }
    }
    .androidBox:hover {
      .androidBtn {
        display: none;
      }
      .qrBox {
        display: flex;
      }
    }
    .androidBtn {
      width: 163px;
      height: 57px;
      background: url("./../../../assets/images/pc/androidBtn.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .tip {
    position: fixed;
    bottom: 163px;
    right: 231px;
    width: 630px;
    height: 50px;
    background: url("./../../../assets/images/pc/tip.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
